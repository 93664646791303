import { BASE_URL } from '../apiConfig';

export const addCommercialContractAPI = async (payload) => {
  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };

  return await fetch(
    `${BASE_URL}/addCommercialContract`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/addcommercialcontract",
    options
  ).then((res) => res.json());
};

export const createCommercialContractAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/createcommercial",
    options
  ).then((res) => res.json());

};

export const allSubjectsLiftedAPI = async (payload) => {

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };
  
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/allsubjectslifted",
    options
  ).then((res) => res.json());
};

export const createContractAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/initcontract",
    options
  ).then((res) => res.json());
};

export const sellerSignAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/sellersign",
    options
  ).then((res) => res.json());
};

export const buyerSignAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/buyersign",
    options
  ).then((res) => res.json());
};

export const updateCommercialAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/updatecommercial",
    options
  ).then((res) => res.json());
};

export const confirmBySellerAPI = async (payload) => {
  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };
  return await fetch(
    `${BASE_URL}/sellerSign`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/confirmbyseller",
    options
  ).then((res) => res.json());
};

export const confirmByBuyerAPI = async (payload) => {
  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };
  return await fetch(
    `${BASE_URL}/buyerSign`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/confirmbybuyer",
    options
  ).then((res) => res.json());
};

export const contractsListByUserAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/getCommercialContractList`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getcontractlist",
    options
  ).then((res) => res.json());
};

export const updateCommercialDBAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/updateCommercialDb`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/updatecommercialdb",
    options
  ).then((res) => res.json());
};

export const updateCommercialDB2API = async (payload) => {
  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };
  
  return await fetch(
    `${BASE_URL}/liftAllSubject`,
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/updatecommercialdb2",
    options
  ).then((res) => res.json());
};

export const updateCommercialDB3API = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/updateWithInterestedBuyer`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/updatecommercialdb",
    options
  ).then((res) => res.json());
};

export const updateProductDBAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/updateLotState`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/updateproductdb",
    options
  ).then((res) => res.json());
};

export const transferProductApi = async (payload) => {
  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };
  return await fetch(
    `${BASE_URL}/transformLot`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/transformproduct",
    options
  ).then((res) => res.json());
};

export const transportProductApi = async (payload) => {
  // Example of making an authenticated request using fetch
  const authToken = localStorage.getItem("authToken");

  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
      apikey: "abc123",
    },
    body: JSON.stringify(payload), // Use the payload without the property_img
  };
  return await fetch(
    `${BASE_URL}/transportLot`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/transportproduct",
    options
  ).then((res) => res.json());
};

export const getProductsListAPI = async (language) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getMasterProductName?language=${language}`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getmasterproductnames",
    options
  ).then((res) => res.json());
};

export const getProductStateAPI = async (language) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getProductState?language=${language}`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getproductstates",
    options
  ).then((res) => res.json());
};

export const getLocationsListAPI = async (language) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getLocation?language=${language}`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getlocation",
    options
  ).then((res) => res.json());
};

export const getFetchLedgerLotAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/fetchLedgerLot`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getlocation",
    options
  ).then((res) => res.json());
};

export const getFetchLedgerLotHistoryAPI = async (payload) => {
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
    body: JSON.stringify(payload),
  };
  return await fetch(
    `${BASE_URL}/fetchLedgerLotHistory`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getlocation",
    options
  ).then((res) => res.json());
};

export const getUOMListAPI = async (language) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getUOMList?language=${language}`, 
    options
  ).then((res) => res.json());
};

export const getProductLotListAPI = async (payload) => {
  const {targetLanguage, userId} = payload;

  let params = new URLSearchParams({
    language: targetLanguage || 'en',
    userId: userId
  }).toString();

  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getProductLotList?${params}`, 
    options
  ).then((res) => res.json());
};

export const getProductNextStateAPI = async () => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getProductNextState?`, 
    // "https://gzisg4fzzpgklhicwsp5vppnum0gbxei.lambda-url.ap-southeast-1.on.aws/getproductstates",
    options
  ).then((res) => res.json());
};

export const getProductNameDescriptionAPI = async (language) => {
  let options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "abc123",
      apikey: "abc123",
    },
  };
  return await fetch(
    `${BASE_URL}/getProductNameDescription?language=${language}`, 
    options
  ).then((res) => res.json());
};