import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocationsList,
  getProductStateList,
  getProductsList,
  productsByUser,
  transferProduct,
  transportProduct,
  updateProductDB,
  getProductLotList,
} from "../Redux/products/slice";
import { PropertyTableConfig, tableConfig } from "../components/mockData";
import moment from "moment";
import ProductPanel from "./ProductPanel";
import { saveProperties, saveDraftProperties, deleteDraftProperties } from "../Redux/properties/PropertiesSlice";
import { deleteValue } from "../Redux/CounterSlice";
import FormDialog, {
  TAG_TRANSFORM_ACTION,
  TAG_TRANSPORT_ACTION,
} from "./TransportationModal";
import TransportationModal from "./TransportationModal";
import {  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem, } from '@mantine/core';
import {  IconRefresh, IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import Dropdown from "../components/Dropdown";
import CreatePropertiesModal from "../components/CreatePropertiesModal";
import LoadImageModal from "../components/LoadImageModal";
import RemoveFieldConfirmationModal from '../components/RemoveFieldConfirmationModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { BASE_URL } from '../Redux/apiConfig';

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },
  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
  resizeHandle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 1,
    backgroundColor: '#fff',
  },
  resizeOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '8px',
    zIndex: 2,
    opacity: 0,
    cursor: 'col-resize',
  },
  tableContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position="apart">
          <div> {/* Wrap the contents of the <th> element */}
            <Text fw={500} fz="sm">
              {children}
            </Text>
            {sorted && <Icon size="0.9rem" stroke={1.5} />} 
          </div>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data, search) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(query);
      } else if (typeof value === 'number' || typeof value === 'bigint') {
        return value.toString().includes(query);
      }
      // Handle other data types if needed
      return false; // Return false for non-searchable types
    })
  );
}

function sortData(data, payload) {
  const { sortBy } = payload;

  let sortedData = [...data];

  if (sortBy) {
    sortedData.sort((a, b) => {
      const valueA = typeof a[sortBy] === 'number'
        ? a[sortBy]
        : (a[sortBy] || '').toString().toLowerCase();

      const valueB = typeof b[sortBy] === 'number'
        ? b[sortBy]
        : (b[sortBy] || '').toString().toLowerCase();

      if (payload.reversed) {
        return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
      }

      return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    });
  }

  return filterData(sortedData, payload.search);
}

export default function MyProducts() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const dataLot = useSelector((state) => state.products.productLotList);
  const locationList = useSelector((state) => state.products.locationsList);
  const productsList = useSelector((state) => state.products.productsList);
  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [openExtrasDialog, setOpenExtrasDialog] = useState(false);
  const [extrasTitle, setExtrasTitle] = useState();
  const [persistedPayload, setPayload] = useState();
  const [tag, setTag] = useState();
  const [selectedAction, setSelectedAction] = useState('');
  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(dataLot || []);
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const [modal, setModal] = useState(false);
  const [loadImageModalVisible, setLoadImageModalVisible] = useState(false);
  const [sellConfirmationFieldName, setSellConfirmationFieldName] = useState('');
  const [showSellConfirmationModal, setShowSellConfirmationModal] = useState(false);
  const selectedLanguage = useSelector((state) => state.language);

  const handleSellButtonClick = (fieldName) => {
    if (fieldName.publish === "N") {
      setSellConfirmationFieldName(fieldName);
      setShowSellConfirmationModal(true);
    } else {
      // Assuming there is a publish method on fieldName, replace it with the actual method
      publish(fieldName); // Call the publish method directly
    }
  };  

  useEffect(() => {
    if (dataLot && dataLot.length > 0) {
      setSortedData(dataLot);
    }
  }, [dataLot]);

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    const sorted = sortData(dataLot, { sortBy: field, reversed, search });
    setSortedData(sorted);
  };
  
  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    const sorted = sortData(dataLot, { sortBy, reversed: reverseSortDirection, search: value });
    setSortedData(sorted);
  };
  
  const getWithdrawLabel = (field) => {
    return field.publish === "Y" ? <FormattedMessage id="myprods.state.withdraw" /> : <FormattedMessage id="myprods.state.sell" />;
  };

  const handleActionChange = (value, data) => {

    setSelectedAction(value);
    setSelectedData(data);

    switch (value) {
      case "LoadImage":
        setLoadImageModalVisible(true);
        break;
      case "UpdateRelease":
        setSelectedForUpdateRelease(data); // Set selected data for update/release
        setModal(true);
        break;
      case "Delete":
        dispatch(deleteDraftProperties(data));
        break;
      // case <FormattedMessage id="myprods.state.withdraw" />:
      //   publish(data);
      //   break;
      case "Sell":
        handleSellButtonClick(data);
        break;
      case "View":
        setShow(true);
        break;
      case "Transport":
        transport(data);
        break;
      case "Transform":
        transfer(data);
        break;
      default:
        break;
    }
  };
  
  const publish = (data) => {
    var obj = {
      ...data,
      contractId: userinfo.product_contractId,
      upc: data.UPC,
      publish: data.publish === "Y" ? "N" : "Y",
      state: data.publishId === 0 ? "ForSale" : "NotForSale",
    };
  
    dispatch(updateProductDB(obj));
  };
  
  useEffect(() => {
    dispatch(productsByUser());
    dispatch(getProductsList());
    dispatch(getLocationsList());
    dispatch(getProductStateList());
    dispatch(getProductLotList());
  }, []);

  useEffect(() => {
    dispatch(getProductLotList());
  }, [dispatch, selectedLanguage]);

  const transfer = (obj) => {
    // console.log({ obj });
    const product_contractId = JSON.parse(
      localStorage.getItem("userinfo")
    ).product_contractId;

    const locationId = locationList.find(state => state.location === obj.location)?.id || null;
    const id_category = productsList.find(state => state.label === obj.productName)?.id_category || null;

    var payload = {
      contractID: product_contractId,
      sku: obj.sku,
      productLotUuid: obj.productID,
      physicalState: obj.physicalState,
      location: obj.location,
      period: obj.period,
      categoryName: obj.productName,
      locationId: locationId,
      category: id_category,
      product_ref_id: obj.product_ref_id,
    };
    setPayload(payload);
    setExtrasTitle(<FormattedMessage id="myprods.xtratitle.transform" />);
    setOpenExtrasDialog(true);
    setTag(TAG_TRANSFORM_ACTION);
  };

  const transport = (obj) => {
    // console.log({ obj });
    const product_contractId = JSON.parse(
      localStorage.getItem("userinfo")
    ).product_contractId;

    const locationId = locationList.find(state => state.location === obj.location)?.id || null;

    var payload = {
      contractID: product_contractId,
      sku: obj.sku,
      productLotUuid: obj.productID,
      location: obj.location,
      period: obj.period,
      locationId: locationId,
      product_ref_id: obj.product_ref_id,
    };

    setPayload(payload);
    setExtrasTitle(<FormattedMessage id="myprods.xtratitle.transport" />);
    setOpenExtrasDialog(true);
    setTag(TAG_TRANSPORT_ACTION);
  };

  const classes = useStyles();
  const [selectedForUpdateRelease, setSelectedForUpdateRelease] = useState(null);

  const handleRefresh = () => {
    dispatch(getProductLotList());
  };
  
  return (
    <>
      <TransportationModal
        modal={openExtrasDialog}
        toggle={() => setOpenExtrasDialog(false)}
        title={extrasTitle}
        data={persistedPayload}
        tag={tag}
        dataLot={dataLot} // Pass dataLot as a prop
      />
      {selectedData && (
        <ProductPanel
          modal={show}
          toggle={() => setShow(false)}
          readonly={true}
          value={selectedData}
        />
      )}
      <Group position="apart" mb="md">
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
          <UnstyledButton
            onClick={handleRefresh}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color: theme.colors.blue[6],
              '&:hover': {
                backgroundColor: theme.colors.gray[0],
              },
            })}
          >
            <IconRefresh size="1.2rem" />
          </UnstyledButton>
        </div>
    </Group>
    <ScrollArea>
      <TextInput
        placeholder={intl.formatMessage({ id:"myprods.search.placeholder" })}
        mb="md"
        icon={<IconSearch size="0.9rem" stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <div  className={classes.tableContainer}>
      <Table striped highlightOnHover withBorder withColumnBorders>
        <thead sx={{ opacity: "1" }}>
          <tr>
            {PropertyTableConfig.map((data, ind) => (
              <Th
                sx={{
                  width: columnWidths[data.accessor],
                  overflow: "auto",
                  resize: "horizontal",
                  borderRight: "1px solid grey",
                }}
                key={ind}
                sorted={sortBy === data.accessor}
                reversed={reverseSortDirection}
                onSort={() => setSorting(data.accessor)}
              >
                {data.column}{" "}
              </Th>
            ))}
            <th><FormattedMessage id="myprods.actionsmenu.title" /></th>
          </tr>
        </thead>
        <tbody>
        {sortData(dataLot, { sortBy, reversed: reverseSortDirection, search }).map((data, ind) => {
            let actions;
            if (data.state === "Draft") {
              actions = [
                {
                  value: "UpdateRelease",
                  label: <FormattedMessage id="myprods.action.updaterelease" />,
                },{
                  value: "Delete",
                  label: <FormattedMessage id="myprods.action.delete" />,
                },{
                  value: "LoadImage",
                  label: <FormattedMessage id="myprods.action.loadimg" />,
                },
              ];
            } else {
              actions = [
                {
                  value: "Sell",
                  label: getWithdrawLabel(data),
                },
                { value: "View", label: <FormattedMessage id="myprods.action.view" /> },
                { value: "Transport", label: <FormattedMessage id="myprods.action.transport" /> },
                { value: "Transform", label: <FormattedMessage id="myprods.action.transform" /> },
                {
                  value: "LoadImage",
                  label: <FormattedMessage id="myprods.action.loadimg" />,
                },
              ];
            }
            return (
              <tr key={ind}>
                {PropertyTableConfig.map((data1, idx) => {
                  let displayValue = data[data1["accessor"]];
                  // Special handling for productNotes
                  if (data1["accessor"] === "productNotes") {
                    displayValue = displayValue.split("\\r\\n")[0];
                  }
                  return (
                    <td
                      key={idx}
                      sx={{
                        width: columnWidths[data1["accessor"]],
                        overflow: "auto",
                      }}
                    >
                      {(data1.type &&
                        data1.type === "date" &&
                        moment(data[data1["accessor"]]).format("DD-MM-yyyy")) ||
                        ""}
                      {(!data1.type &&
                        !data1.link &&
                        displayValue) ||
                        data1.defaultValue}
                      {data1.link && (
                        <a
                          rel="noopener noreferrer"
                          href={`https://hashscan.io/testnet/contract/${
                            data[data1["accessor"]]
                          }`}
                          target="_blank"
                          style={{ color: "#2c5810" }}
                        >
                          {data[data1["accessor"]]}
                        </a>
                      )}
                      {data1.type === 'pdf' && (
                        <a
                          href={data[data1["accessor"]]}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          onClick={async (e) => {
                            e.preventDefault();

                            // Call getProductCertificate to ensure the product exists
                            try {
                              const productResponse = await fetch(`${BASE_URL}/getProductCertificate?product_id=${data.productID}&targetLanguage=${selectedLanguage}`);

                            if (!productResponse.ok) {
                                console.error('Error fetching product certificate:', productResponse.statusText);
                                return;
                              }
                              // getProductCertificate succeeded, now call certificateContent
                              const certificateResponse = await fetch(`${BASE_URL}/certificateContent?product_id=${data.productID}`);
                              if (certificateResponse.ok) {
                                // API call was successful, you can handle the response or redirect to the PDF URL
                                window.open(certificateResponse.url, '_blank');
                              } else {
                                // API call failed, handle the error
                                console.error('Error fetching PDF:', certificateResponse.statusText);
                              }
                            } catch (error) {
                              console.error('Error fetching product certificate or PDF:', error.message);
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faFilePdf} />
                        </a>
                      )}
                    </td>
                  );
                })}
                <td>
                  <Dropdown
                    actions={actions}
                    handleActionChange={handleActionChange}
                    data={data}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      </div>
      {/* <CreatePropertiesModal
        modal={modal}
        toggle={() => setModal(false)}
        selectedData={selectedForUpdateRelease}
        onSave={(obj, cb) => onSave(obj, cb)}
        onDraftSave={(obj, cb) => onDraftSave(obj, cb)}
      /> */}
      <LoadImageModal
        modal={loadImageModalVisible}
        toggle={() => setLoadImageModalVisible(!loadImageModalVisible)}
        selectedData={selectedData}
        onSave={(obj, toggle) => {
          // Handle saving logic here
          setLoadImageModalVisible(false); // Close the modal after saving
        }}
      />
      </ScrollArea>
      <RemoveFieldConfirmationModal
        fieldName={sellConfirmationFieldName}
        onConfirm={() => {
          // Handle the sell confirmation logic here
          publish(sellConfirmationFieldName); 
          setShowSellConfirmationModal(false); // Close the modal after confirming
        }}
        onCancel={() => setShowSellConfirmationModal(false)}
        opened={showSellConfirmationModal}
      />
    </>
  );
}
