import { CircularProgress, FormControl, Grid, Input, InputLabel, MenuItem, NativeSelect, OutlinedInput, Stack, Select, TextareaAutosize, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FileButton, Button as MButton, Autocomplete, TextInput, Textarea, Select as MSelect, Title, Loader, NumberInput, Group, Center, Badge } from '@mantine/core';

import { FormattedMessage, useIntl } from "react-intl";
import { IconGripVertical } from "@tabler/icons-react";
import { getLocationsList, getProductStateList, getProductsList, getUOMList, setUOMList, getProductNameDescription, setProductNameDescription  } from "../Redux/products/slice";
import { getCategories } from "../Redux/options/slice";

function DescriptionList({ title, description, setDescription }) {

  // Get productNameDescription from Redux store
  const productNameDescription = useSelector((state) => state.products.productNameDescription);

  // Filter the productNameDescription based on title
  const productNameDescriptionList = productNameDescription?.filter(
    (item) => item.ProductNameId === title
  ) || [];

  // Local state to track temporary changes
  const [localDescriptions, setLocalDescriptions] = useState(() =>
    productNameDescriptionList.map((desc) => ({
      ...desc,
      descriptiontext: desc.descriptiontext || "", // Ensure `descriptiontext` exists
    }))
  );

  const handleDescriptionChange = (id, value) => {
    // Update local state
    const updatedLocalDescriptions = localDescriptions.map((desc) =>
      desc.DescriptionId === id ? { ...desc, description: value } : desc
    );
    setLocalDescriptions(updatedLocalDescriptions);

    // Construct the combined description string
    const descriptionString = updatedLocalDescriptions
      .map((item) => `${item.Description || "N/A"}: ${item.description || "N/A"} `)
      .join("\n\r");

    // Update the parent description state
    setDescription(descriptionString);
  };

  useEffect(() => {
    setLocalDescriptions(
      productNameDescriptionList.map((desc) => ({
        ...desc,
        descriptiontext: desc.descriptiontext || "", // Ensure `descriptiontext` exists
      }))
    );
  }, [productNameDescriptionList]);

  return productNameDescriptionList && productNameDescriptionList.length > 0 ? (
    productNameDescriptionList.map((desc) => (
      <Group
      key={desc.DescriptionId}
      mt="xs"
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "10px",
      }}
    >
      <IconGripVertical size={18} style={{ flexShrink: 0 }} />
      <Badge
        color="blue"
        size="lg"
        styles={{
          root: {
            height: 'auto',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            padding: '8px',
            lineHeight: 1.4,
            textAlign: 'left',
            flex: '1',
            minWidth: '200px', // Adjust this value as needed
            maxWidth: '300px'  // Adjust this value as needed
          },
          label: {
            whiteSpace: 'pre-wrap',
            display: 'block',
            width: '100%', 
          },
          inner: {
            width: '100%',    // Ensures inner container takes full width
            overflow: 'visible'
          }
        }}
        title={desc.Description || "N/A"}
      >
        {desc.Description || "N/A"}
      </Badge>
      <Textarea
        placeholder="Value"
        value={desc.descriptiontext}
        onChange={(e) => handleDescriptionChange(desc.DescriptionId, e.target.value)}
        autosize
        minRows={2}
        maxRows={4}
        style={{ flex: 2 }}
      />
    </Group>
    ))
  ) : (
    // Fallback Textarea when no data is available
    <Textarea
      aria-label="Minimum height"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      label="Description"
      minRows={3}
      placeholder="Enter description"
      style={{ width: "100%", marginTop: "5px", padding: "15px" }}
      maxLength={200}
    />
  );
}

function MantinePhysicalStateSelect({ title, physicalState, setPhysicalState, productStateList }) {
  const intl = useIntl();
  return (
    <MSelect
      label={<FormattedMessage id="product.physicalstate" />} // Label for the dropdown
      value={physicalState} // Current selected value
      onChange={setPhysicalState} // Function to handle changes
      data={productStateList
        .filter((product) => product.id_category === title && product.product_state !== null)
        .sort((a, b) => {
          if (a.mps_id !== b.mps_id) {
            return a.mps_id - b.mps_id; // Sort numerically by ActionId
          }
          const stateA = a.product_state || '';
          const stateB = b.product_state || '';
          return stateA.localeCompare(stateB);
        })
        .map((product, index) => ({
          value: product.product_state,
          label: `${index}: ${product.product_state} - ${product.ActionName}`,
        }))}
      placeholder={intl.formatMessage({ id: 'product.physicalstate' })} 
      dropdownPosition="bottom" // Position dropdown below the input
      styles={{
        dropdown: { maxHeight: 150, overflowY: 'auto' }, // Limit dropdown height
        label: { marginBottom: 8 }, // Optional: Adjust label styles
        item: { whiteSpace: 'nowrap' }, // Prevent text wrapping in dropdown
      }}
      withinPortal={false} // Keeps the dropdown in the DOM hierarchy
    />
  );
}

function CreatePropertiesModal({ toggle, modal, selectedData, onSave, onDraftSave }) {
  const dispatch = useDispatch();
  const userinfo = JSON.parse(localStorage.getItem('userinfo'));
  const { productStateList, productsList, UOMList } = useSelector((state) => state.products);
  const productNameDescription = useSelector(
    (state) => state.productNameDescription // Adjust based on your state structure
  );

  useEffect(() => {
    if (modal) {
      // Dispatch fetch action here
      dispatch(getProductNameDescription());
    }
  }, [modal, dispatch]);
  
  let { locationsList } = useSelector(
    (state) => state.products
  );

  locationsList = locationsList
    .slice()
    .sort((a, b) => (a.location && b.location ? a.location.localeCompare(b.location) : 0));

  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");

  useEffect(() => {
    if (!modal)
      clearData();
  }, [modal]);

  const sortedProductList = Array.from(productsList)
    .filter(item => typeof item.label === 'string') // Filter out objects without a valid label property
    .sort((a, b) => a.label.localeCompare(b.label));

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [city, setCity] = useState("");
  const [physicalState, setPhysicalState] = useState("");
  const [buildYear, setBuildYear] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [quantity, setQuantity] = useState(null);
  const [fromPeriod, setFromPeriod] = useState(new Date().toISOString().split('T')[0]);
  const [toPeriod, setToPeriod] = useState(new Date().toISOString().split('T')[0]);
  const [len, setLen] = useState(0);
  const CHAR = 160;
  const [savingData, setSavingData] = useState(false);
  const [savingDraftData, setSavingDraftData] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [selectedUOM, setSelectedUOM] = useState("");

  const saveData = () => {
    setSavingData(true);
    // Assuming title and physicalState are selected values
    const selectedProductState = physicalState;

    // Assuming locationsList is a dictionary with id and label
    const selectedLocation = locationsList.find(location => location.location === city);
    const productName = sortedProductList.find(product => product.id_category === title)?.label;
    const productRefId = productStateList.find(state => state.product_state === selectedProductState && state.id_category === title && state.product_state !== null)?.product_ref_id || null;
    const locationsIds = selectedLocation?.id || null;
    const UOMId = UOMList.find(state => state.UOM === selectedUOM)?.UOMId || null; 
    const quantityNum = Number(quantity);

    var obj = {
      productName: productName,
      productNotes: description,
      property_img: imgAfterCrop,
      physicalState: physicalState,
      period: `${fromPeriod} - ${toPeriod}`,
      quantity,
      location: city,
      ownerAccountId: userinfo.accountId,
      OwnerId: userinfo.userId,
      ProductId: productRefId,
      Quantity: quantityNum,
      UOMId: UOMId,
      QuantityUOM: `${quantity} ${selectedUOM}`,
      RegionId: locationsIds,
      Period: `${fromPeriod} - ${toPeriod}`,
      PublishId: 0,
      ProductLotDetail: description,
    };

    onSave(obj, toggle);
    setSavingData(false);
  };

  const saveDraftData = () => {
    setSavingDraftData(true);
    // Assuming title and physicalState are selected values
    const selectedProductState = physicalState;

    // Assuming locationsList is a dictionary with id and label
    const selectedLocation = locationsList.find(location => location.location === city);
    const productName = sortedProductList.find(product => product.id_category === title)?.label;
    const productRefId = productStateList.find(state => state.product_state === selectedProductState && state.id_category === title && state.product_state !== null)?.product_ref_id || null;
    const locationsIds = selectedLocation?.id || null;
    const UOMId = UOMList.find(state => state.UOM === selectedUOM)?.UOMId || null; 
    const quantityNum = Number(quantity);

    var draftObj = {
      productName: productName,
      productNotes: description,
      property_img: imgAfterCrop,
      physicalState: physicalState,
      period: `${fromPeriod} - ${toPeriod}`,
      quantity,
      location: city,
      ownerAccountId: userinfo.accountId,
      OwnerId: userinfo.userId,
      ProductId: productRefId,
      Quantity: quantityNum,
      UOMId: UOMId,
      RegionId: locationsIds,
      Period: `${fromPeriod} - ${toPeriod}`,
      PublishId: 0,
      ProductLotDetail: description,
      // productLotUuid: selectedData.productID,
    };

    onDraftSave(draftObj, toggle); // Call the onDraftSave method
    setSavingDraftData(false);
  };

  const clearData = () => {
    setTitle("");
    setDescription("");
    setCategory("");
    setBuildYear("");
    setCity("");
    setPrice("");
    setQuantity("");
    setFromPeriod("");
    setToPeriod("");
    setPhysicalState("");
    setImage("");
    setImgAfterCrop("");
    setCurrentPage("choose-img");
    setUOMList("");
  };

  function imageUploaded(file) {
    // var file = document.getElementById("fileType").files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = function () {
        const base64String = reader.result;
        setImageFile({ name: file.name, data: base64String });
      };

      reader.readAsDataURL(file);
    }
  }

  useEffect(() => {
    // console.log(description.length, len);
    setLen(CHAR - description.length);
  }, [description]);

  const intl = useIntl();

  useEffect(() => {
    if (modal) {
      dispatch(getProductNameDescription());
      dispatch(getLocationsList());
      dispatch(getProductStateList());
      dispatch(getProductsList());
      dispatch(getCategories());
      dispatch(getUOMList());
    }
  }, [modal, dispatch]); // This only runs to trigger dispatches when modal opens or closes
  
  useEffect(() => {
    // Check if selectedData is valid and update state accordingly
    if (selectedData) {
      setTitle(selectedData.productName || "");
      setDescription(selectedData.productNotes || "");
      setPhysicalState(selectedData.physicalState || "");
      setCity(selectedData.location || "");
      setCategory(selectedData.categoryId || ""); // Assuming category ID is stored directly
      setPrice(selectedData.price || "");
      setQuantity(selectedData.quantity || "");
      setFromPeriod(selectedData.fromPeriod || new Date().toISOString().split('T')[0]);
      setToPeriod(selectedData.toPeriod || new Date().toISOString().split('T')[0]);
      setSelectedUOM(selectedData.UOMId || ""); // Assuming UOMId is stored directly
      setImageFile(selectedData.property_img || ""); // Assuming this is how image data is stored
    }
  }, [selectedData]); // This handles updates when selectedData changes  

  // Calculate the smallest product IDs for each category
  const calculateSmallestProductIds = () => {
    const smallestProductIds = {};
    sortedProductList.forEach(product => {
      if (!smallestProductIds[product.id_category] || product.id < smallestProductIds[product.id_category].id) {
        smallestProductIds[product.id_category] = { id: product.id_category, label: product.label };
      }
    });
    return smallestProductIds;
  };

  // Get the smallest product IDs
  const smallestProductIds = calculateSmallestProductIds();

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // Find the selected option based on the input value
    const selectedOption = Object.values(smallestProductIds).find(option => option.label === inputValue.value);
    if (selectedOption) {
      setTitle(parseInt(selectedOption.id)); // Set title as the id of the selected option
    }
  }, [inputValue, smallestProductIds]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
        <ModalHeader toggle={toggle}>{<FormattedMessage id="modal.createprod.add" />}</ModalHeader>
        <ModalBody>
          <Grid container spacing={1}>
            <Grid item>
              {/* <FormControl sx={{ m: 0, minWidth: 120 }}>
                <InputLabel htmlFor="demo-dialog-native"><FormattedMessage id="product.name" /></InputLabel>
                <Select
                    native
                    value={title}
                    onChange={(e) => setTitle(parseInt(e.target.value))}
                    input={<OutlinedInput label="Product Name" id="demo-dialog-native" />}
                  >
                  <option aria-label="None" value="" />
                    {smallestProductOptions}
                </Select>
              </FormControl> */}
              <Autocomplete
                placeholder={intl.formatMessage({ id: "product.name" })}
                data={Object.values(smallestProductIds).map(({ id, label }) => label)}
                withScrollArea={true}
                onItemSubmit={(selectedLabel) => {
                  const selectedOption = Object.values(smallestProductIds).find(
                    (option) => option.label === selectedLabel.value
                  );
                  if (selectedOption) {
                    setTitle(selectedOption.id); // Set title with the id of the selected option
                    setInputValue(selectedLabel); // Set input value to the selected label
                  }
                }}
                sx={{
                  '.mantine-Autocomplete-dropdown': {
                    maxHeight: '200px', // Maximum height for the dropdown
                    height: 'auto', // Allow it to adjust to the content height if smaller
                  },
                  '.mantine-Autocomplete-item': {
                    whiteSpace: 'nowrap', // Prevent text from wrapping and ensure the height fits well
                  },
                }}
              />
            </Grid>
            <Grid item>
              <DescriptionList
                title={title}
                description={description}
                setDescription={setDescription}
              />
            </Grid>
            {/* <Grid item>
              <TextareaAutosize
                aria-label="minimum height"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label= {intl.formatMessage({ id:"product.description" })}
                minRows={3}
                placeholder={intl.formatMessage({ id:"product.description" })}
                style={{ width: "100%", marginTop: "5px", padding: "15px" }}
                maxLength={CHAR}
              />
              <Typography variant="subtitle2"><FormattedMessage id="userInput.msg.charaleft" /> {len}</Typography>
            </Grid> */}
            <Grid item container spacing={1}>
              <Grid item xs={6}>
                <NumberInput
                  id="outlined-basic" // Keeping the same id as your MUI TextField
                  value={quantity}
                  onChange={(value) => setQuantity(value)} // Mantine directly returns the numeric value
                  label={intl.formatMessage({ id: "product.quantity" })} // Setting the label as in the original
                  variant="filled" // Mantine variant closest to "outlined"
                  min={0} // Sets minimum value to 0, as in the original InputProps
                  fullWidth // Ensures the input takes the full width of its container
                  styles={{
                    input: {
                      backgroundColor: '#fff', // Optional: Adjust background color if needed
                      borderColor: '#ced4da', // Ensures a consistent border style
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <MSelect
                  label={<FormattedMessage id="product.uom" />}
                  value={selectedUOM}
                  onChange={setSelectedUOM}
                  data={UOMList.map((uom) => ({
                    value: uom.UOM,
                    label: uom.UOM,
                  }))}
                  maxDropdownHeight={100} // Limiting dropdown height to 100px
                  withinPortal={false} // Keeps dropdown within the DOM hierarchy
                  dropdownPosition="bottom" // Sets dropdown to open below the input
                  styles={{
                    dropdown: {
                      maxHeight: 100, // Ensure the dropdown max height is set here too
                      overflowY: 'auto', // Allows scrolling within the dropdown
                    },
                    item: {
                      maxWidth: 100, // Ensure items are constrained
                      whiteSpace: 'nowrap', // Prevents item text from wrapping and causing overflow
                    },
                    input: {
                      minWidth: 120, // Consistent input sizing
                    },
                  }}
                />
              </Grid>
            </Grid>
              <Grid item>
                <MantinePhysicalStateSelect
                  title={title}
                  physicalState={physicalState}
                  setPhysicalState={setPhysicalState}
                  productStateList={productStateList}
                />
              </Grid>
            <Grid item>
              <Autocomplete
                // label={intl.formatMessage({ id: "product.location" })}
                placeholder={intl.formatMessage({ id: "product.location" })}
                data={locationsList.map(location => location.location)}
                value={city}
                onChange={setCity}
                maxDropdownHeight={200} // Limit the dropdown height to 200px
                sx={{
                  '.mantine-Autocomplete-dropdown': {
                    maxHeight: '200px', // Maximum height for the dropdown
                    height: 'auto', // Allow it to adjust to the content height if smaller
                  },
                  '.mantine-Autocomplete-item': {
                    whiteSpace: 'nowrap', // Prevent text from wrapping and ensure the height fits well
                  },
                }}
              />
            </Grid>
            <Grid item>
              <div><FormattedMessage id="product.period" /></div>
              <TextField
                id="outlined-basic"
                type={"date"}
                sx={{ width: "50%" }}
                value={fromPeriod}
                placeholder={""}
                onChange={(e) => setFromPeriod(e.target.value)}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                type={"date"}
                sx={{ width: "50%" }}
                value={toPeriod}
                placeholder={""}
                onChange={(e) => setToPeriod(e.target.value)}
                variant="outlined"
              />
                {/* <DateInput
                  value={new Date(fromPeriod)} // Convert the string date to Date object
                  onChange={(date) => setFromPeriod(date.toISOString().split('T')[0])} // Update state as a string in 'YYYY-MM-DD' format
                  label="From" // Add a label if needed
                  placeholder="" // Placeholder text
                  sx={{ width: '50%', marginRight: '8px' }} // Styling similar to the TextField
                  locale="en" // Set the locale if needed
                />
                <DateInput
                  value={new Date(toPeriod)} // Convert the string date to Date object
                  onChange={(date) => setToPeriod(date.toISOString().split('T')[0])} // Update state as a string in 'YYYY-MM-DD' format
                  label="To" // Add a label if needed
                  placeholder="" // Placeholder text
                  sx={{ width: '50%' }} // Styling similar to the TextField
                  locale="en" // Set the locale if needed
                /> */}
            </Grid>
            <Grid item>
            </Grid>
            <Grid item>
              <FileButton style={{ backgroundColor: 'grey', color: 'white' }} onChange={(file) => imageUploaded(file)} accept="image/*">
                {(props) => <MButton {...props}><FormattedMessage id="button.chooseimg"></FormattedMessage> </MButton>}
              </FileButton>
                {imageFile && (
                  <div style={{ marginTop: '10px' }}>
                    {imageFile.name}
                  </div>
                )}
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <MButton color="primary" onClick={() => saveDraftData() }>
            {savingDraftData ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              <FormattedMessage id="button.savedraft" />
            )}
          </MButton>{" "}
          <MButton color="primary" onClick={() => saveData()}>
            {savingData ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              <FormattedMessage id="button.save" />
            )}
          </MButton>
          <MButton
            color="secondary"
            onClick={() => {
              toggle();
            }}
          >
            <FormattedMessage id="button.cancel" />
          </MButton>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CreatePropertiesModal;