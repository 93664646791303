import { Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BuyConfirmationModal from "./Buy";
import { addCommercialContract, updateCommercialDB3 } from "../Redux/products/slice";
import { setShowLogin } from "../Redux/user/slice";
import { toast } from "react-toastify";
import { ToastProperties } from '../utils/constants/toastProperties';
import { FormattedMessage, useIntl } from 'react-intl';
import { BASE_URL } from '../Redux/apiConfig';
import { Modal as MantineModal, Button as MantineButton, Portal } from '@mantine/core';

const ImageBase64 = (dataImg) => {
  const img = new Image();
  img.src = dataImg
  img.alt = "Product Image"
  return img
}

const requiredPayload = {
  contractId: "0.0.3520248",
  sellerId: "0.0.57939",
  buyerId: "0.0.57939",
  contractDetails:
    "Delivery Santos/Period October 2023/Incoterm FOB/3000 $ per MT /Sample/Arbitration/Validity offer 1 day",
  price: "3000 $/MT",
};

function SalePanel({
    toggle,
    modal = false,
    title,
    value,
    buyerID,
    SellerID,
    readonly,
  }) {
  const dispatch = useDispatch();
  const [buyerConfirmationModal, setBuyerConfirmation] = useState(false);
  const { userInfo: userDetails } = useSelector(s => s.user);
  const [imageUrl, setImageUrl] = useState("");
  const intl = useIntl();
  const [certModalOpen, setCertModalOpen] = useState(false);

  const openCertModal = () => {
    toggle(); // Close SalePanel first
    setCertModalOpen(true);
  };

  useEffect(() => {
    const fetchImages = async () => {
      const imageUrl = await getImages(value);
      setImageUrl(imageUrl);
    };
    fetchImages();
  }, [value]);

  const getImages = async (value) => {
    const imageUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.productRefID}.png`;
    const defaultImage= `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.product_name_id}.jpg`; 
    try {
      const response = await fetch(imageUrl);
      if (response.ok) {
        return imageUrl;
      } else {
        console.error("Error fetching image. Server returned:", response.status);
        return defaultImage;
      }
    } catch (error) {
      return defaultImage;
    }  
  };

  const handleInterested = (v) => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    if (v.SellerID !== userinfo.userId) {
      const payload = {
        "contractRefId": v.contractRefID,
        "SELLERID": v.ownerID,
        "BUYERID": userinfo.userId,
        "state": "InNegotiation",
        sku: -1,
        productRefId: v.productID,
      }
      dispatch(updateCommercialDB3(payload))

      setTimeout(() => {
        toggle()
      }, 3000)
    } else {
      toast.error(<FormattedMessage id="toast.marketplace.samesellerandbuyer" />, {
        ...ToastProperties
      });
    }
  }

  const handleCreateContract = () => {
    // dispatch(
    //   createCommercialContract({ payload: contractPayload, cb: toggle })
    // );

    const getUser = localStorage.getItem("userLogged");
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    console.log({ value, userinfo, getUser })
    if (getUser && userinfo) {
      handleInterested(value)
    } else {
      dispatch(setShowLogin({ value: true, cb: () => handleInterested(value) }))
    }

  };

  const updatePayload = () => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))

    return {
      productRefId: value.productID,
      contractId: userinfo.commercial_contractId,
      sellerId: value.ownerID,
      buyerId: userinfo.userId,
      contractDetails: "Delivery Santos/Period October 2023/Incoterm FOB/3000 $ per MT /Sample/Arbitration/Validity offer 1 day",
      price: "0",
      location: value.location,
      period: value.period,
      quantity: value.quantity,
      productNotes: value.productNotes
    }
  };

  const handleConfirmOrder = () => {

    dispatch(addCommercialContract(updatePayload()));
    // addCommercialContract
    // createCommercialContract
  };


  return (
    <>
    <Modal isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
      {title && <ModalHeader toggle={toggle}>{"Sale - " + title}</ModalHeader>}
      {!title && <ModalHeader toggle={toggle}>{<FormattedMessage id="modal.sale.title1" />}</ModalHeader>}
      <ModalBody>
        <BuyConfirmationModal
          modal={buyerConfirmationModal}
          toggle={() => {
            setBuyerConfirmation(false);
            toggle();
          }}
          buyerID={"0.0.48903622"}
          SellerID={"0.0.48903965"}
          buyerName={"Test Buyer"}
          sellerName={"Test Seller"}
          value={value}
        ></BuyConfirmationModal>
        <Grid container>
          <Grid item>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "10px" }}>
              <img style={{ width: '200px', height: '200px' }} src={imageUrl}/>
            </div>
          </Grid>
          <Grid item sx={{ border: "1px solid gray" }}>
            <Grid container direction={"row"}>
              <Grid item sx={{ width: "100%" }}>
                <Description
                  label={<FormattedMessage id="product.name1" />}
                  value={value?.productName}
                />
                <Description
                  label={<FormattedMessage id="product.description1" />}
                  value={value?.productNotes}
                />
                <Description label={<FormattedMessage id="product.quantity1"/>} value={value?.quantity} />
                <Description label={<FormattedMessage id="contract.seller1" />} value={value?.SellerID} />
                <Description label={<FormattedMessage id="product.price1" />} value={value?.price} />
                <Description label={<FormattedMessage id="product.deliverloc1" />} value={value?.location} />
                {/* <Description
                  label={"Physical State:"}
                  value={value?.physicalState}
                /> */}
                <Description label={<FormattedMessage id="product.deliverprd1" />} value={value?.period} />
                <Description label={<FormattedMessage id="contract.TnC1" />} value={value?.contractDetails} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalBody>
      {!readonly && (
        <ModalFooter  style={{ justifyContent: "space-between" }}>
        {/* <a
          href={value?.certificateUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
              textDecoration: 'underline',
              color: '#007bff', // Bootstrap primary link color
              cursor: 'pointer',
          }}
          onClick={async (e) => {
            e.preventDefault();
            try {
              const productResponse = await fetch(`${BASE_URL}/getProductCertificate?product_id=${value.productRefID}&targetLanguage=${intl.locale}`);
              if (!productResponse.ok) {
                console.error('Error fetching product certificate:', productResponse.statusText);
                return;
              }
              const certificateResponse = await fetch(`${BASE_URL}/certificateContent?product_id=${value.productRefID}`);
              if (certificateResponse.ok) {
                window.open(certificateResponse.url, '_blank');
              } else {
                console.error('Error fetching PDF:', certificateResponse.statusText);
              }
            } catch (error) {
              console.error('Error fetching product certificate or PDF:', error.message);
            }
          }}
        >
          <FormattedMessage id="product.certificate.check" />
        </a> */}
        <MantineButton 
            variant="subtle" 
            size="xs"
            onClick={() => setCertModalOpen(true)}
            style={{ padding: '4px 8px' }}
          >
            <FormattedMessage id="product.certificate.check" />
          </MantineButton>
          <Button color="secondary" onClick={toggle}>
            <FormattedMessage id="button.cancel" />
          </Button>
        </ModalFooter>
      )}
      <ModalFooter style={{ display: "flex", justifyContent: "center" }}>
        {!readonly && (
          <>
            <Button color="warning" onClick={handleCreateContract}>
              <FormattedMessage id="button.interested" />
            </Button>
            {/* <Button color="success" onClick={handleConfirmOrder}>
              Confirm Order
            </Button> */}
          </>
        )}
        {readonly && (
          <>
            {/* <Button color="success" onClick={handleConfirmOrder}>
              Confirm Order
            </Button> */}
            {/* <Button color="info" onClick={handleCommercialContract}>
              Commercial Contract
            </Button> */}
            {/* <Button color="danger" onClick={handleBuyerSign}>
              Buyer Sign
            </Button>
            <Button color="success" onClick={handleSellerSign}>
              Seller Sign
            </Button> */}
          </>
        )}
      </ModalFooter>
    </Modal>
    <Portal>
      <MantineModal
        opened={certModalOpen}
        onClose={() => setCertModalOpen(false)}
        title="Product Certificate"
        size="50%"
        centered
        withinPortal={true}
        withOverlay={false}  // This prevents the shadow overlay
        lockScroll={false}   // This prevents scrolling from being locked
        styles={{
          root: { 
            position: 'fixed', 
            zIndex: 9999
          },
          modal: { 
            position: 'relative', 
            zIndex: 9999,
            boxShadow: '0 0 10px rgba(0,0,0,0.1)' // Optional: add some shadow to the modal itself
          },
          inner: {
            padding: 0
          }
        }}
      >
        <iframe
          src={`${window.location.origin}/certificate/${value.productSKU[0]}`}
          style={{ width: '100%', height: '80vh', border: 'none' }}
          title="Certificate"
        />
      </MantineModal>
      </Portal>
    </>
  );
}

const Description = ({ label, value }) => {
  return (
    <Grid sx={{ border: "1px solid gray" }} container direction={"row"}>
      <Grid item sx={{ width: "50%" }}>
        {label}
      </Grid>
      <Grid item sx={{ width: "50%" }}>
        {value}
      </Grid>
    </Grid>
  );
};

export default SalePanel;
