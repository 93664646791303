import { useState } from 'react';
import { Button } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import MenuPanel from "../components/MenuPanel";
import SecondFooter from "../components/SecondFooter";
import Footer from "../components/Footer";
import { LoginModal } from "../components/LoginModal";
import CertificatePage from "../components/CertificatePage";
import { FormattedMessage, useIntl } from "react-intl";
import { Text, Title, TextInput, Button as MantineButton } from "@mantine/core";
import { FaSearch } from 'react-icons/fa';
import { useDispatch} from "react-redux";
import { getFetchLedgerLot, getFetchLedgerLotHistory } from "../Redux/products/slice";
import styled from 'styled-components';

const style = {
  container: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    padding: '40px', // Adding padding to the container
    boxSizing: 'border-box', // Ensuring padding doesn't increase the total width
    overflowY: "auto", 
  },
  explorer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "800px",
    width: "100%",
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    maxWidth: '400px',
    margin: '20px auto'
  },
  copyButton: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  }
};

const TransactionExplorer = () => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      await dispatch(getFetchLedgerLot({ inputValue: String(inputValue || '') }));
      await dispatch(getFetchLedgerLotHistory({ inputValue: String(inputValue || '') }));      
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyLink = () => {
    const certificateLink = `${window.location.origin}/certificate/${inputValue}`;
    navigator.clipboard.writeText(certificateLink);
  };

  const TitleDescriptionWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  `;  

  return (
    <div>
      <MenuPanel level={1}/>
      <div className="ex" style={style.explorer}>
        <div style={style.container}>
          <TitleDescriptionWrapper style={{marginTop: "40px"}}>
            <Title order={3} weight={200} align="center" style={{fontFamily: 'Montserrat, sans-serif', marginTop: "40px", marginBottom: "40px" }}>
              <FormattedMessage id="explorer.title" />
            </Title>
            <Text style={{fontFamily: 'Montserrat, sans-serif', textAlign: 'center'}}>
              <FormattedMessage id="explorer.text" />
            </Text>
          </TitleDescriptionWrapper>
        </div>
        <div style={style.searchContainer}>
          <TextInput
            placeholder={intl.formatMessage({ id: "explorer.search.placeholder" })}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <MantineButton variant="outline" onClick={handleSearch} loading={isLoading}>
            <FaSearch />
          </MantineButton>
        </div>
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', width: '100%' }}>
              <Button 
                variant="contained"
                startIcon={<ContentCopy />}
                onClick={() => handleCopyLink(inputValue)}
              >
                <FormattedMessage id="button.dload.pdf" />
              </Button>
            </div>
            {inputValue && <CertificatePage/>}
          </div>
        </div>
      <SecondFooter />
      <Footer />
      <LoginModal/>
    </div>
  );
};

export default TransactionExplorer;