import { Title } from "@mui/icons-material"
import { Grid } from "@mui/material";

const style={

    gridContainer:{
        width: '75vw',
    },

    bigText:{
        fontSize: '55px',
        color: "white",
        fontWeight: "bold",
        fontFamily: "'rubik', sans-serif"
        
    },



}

const TitleDiv = () => {
     return (
        <Grid container sx={style.gridContainer} direction={"column"}>
            <Grid item sx={style.bigText}>

            </Grid>
        </Grid>

     )
}

export default TitleDiv;



