import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getFetchLedgerLot, getFetchLedgerLotHistory } from "../Redux/products/slice";
import { BASE_URL } from '../Redux/apiConfig';
import { Timeline, Text, Title, Center, Button, Loader, Badge, Group, MantineProvider, Card } from '@mantine/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly, faGears, faCashRegister, faCircleInfo, faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';

const Row = ({ children }) => (
  <div className="row" style={{ alignItems: "center", justifyContent: "center", marginTop: "40px" }}>{children}</div>
);

const Column = ({ children }) => (
  <div className="col" style={{ alignItems: "center", justifyContent: "center" }}>{children}</div>
);

const getImages = async (value) => {
  const imageUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.productRefID}.png`;
  const defaultImage= `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${value.product_name_id}.jpg`; 
  try {
    const response = await fetch(imageUrl);
    if (response.ok) {
      return imageUrl;
    } else {
      // console.error("Error fetching image. Server returned:", response.status);
      return defaultImage;
    }
  } catch (error) {
    return defaultImage;
  }  
};

const ProductState = ({ product }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      if (product?.result?.ProductID) {
        const url = await getImages({
          productRefID: product.result.ProductID,
          product_name_id: product.result.ProductNameId
        });
        setImageUrl(url);
      }
    };
    fetchImage();
  }, [product]);

  const ownerDetails = {
    "Owner Address": product?.result?.OwnerPublicAddress,
    "Product Name": product?.result?.ProductName,
    "UPC": product?.result?.UPC,
  };

  const productSpecifications = Object.entries(product?.result || {})
    .filter(([key]) => !["OwnerPublicAddress", "ProductName", "UPC"].includes(key))
    .map(([key, value]) => ({ label: key, value }));

  return (
    <div style={{ marginTop: "20px", maxWidth: "600px", margin: "auto" }}>
      <Card withBorder radius="md" style={{ marginBottom: "20px" }}>
        <Card.Section>
          <Title order={4} style={{ padding: "10px 20px" }}>
            Owner Details
          </Title>
        </Card.Section>
        <Group direction="column" spacing="sm" style={{ padding: "20px" }}>
          {Object.entries(ownerDetails).map(([key, value]) => (
            <div key={key}>
              <Text size="sm" weight={500}>
                {key}:
              </Text>
              <Text size="sm" color="dimmed">
                {value || "N/A"}
              </Text>
            </div>
          ))}
        </Group>
        {imageUrl && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "10px" }}>
            <img style={{ width: '200px', height: '200px' }} src={imageUrl} alt="Product" />
          </div>
        )}
      </Card>

      <Card withBorder radius="md">
        <Card.Section>
          <Title order={4} style={{ padding: "10px 20px" }}>
            Product Specifications
          </Title>
        </Card.Section>
        <Group direction="column" spacing="sm" style={{ padding: "20px" }}>
          {productSpecifications.map(({ label, value }) => (
            <div key={label}>
              <Text size="sm" weight={500}>
                {label}:
              </Text>
              <Text size="sm" color="dimmed">
                {value || "N/A"}
              </Text>
            </div>
          ))}
        </Group>
      </Card>
    </div>
  );
};

function ProductTimeline({ productHist }) {
  const modifiedProductHist = Object.fromEntries(
    Object.entries(productHist).map(([timestamp, item], index) => {
      // Clone the item to avoid modifying the original
      const modifiedItem = { ...item };
   
      // First element always set to 'Product creation'
      if (index === 0) {
        modifiedItem.method = 'productcreation';
      } else {
        // Set method based on conditions for other elements
        if (!modifiedItem.method) {
          if (modifiedItem.event) {
            modifiedItem.method = modifiedItem.event;
          } else if (modifiedItem.physicalState) {
            modifiedItem.method = 'transform';
          } else if (modifiedItem.transportDescription) {
            modifiedItem.method = 'transport';
          }
        }
      }
   
      return [timestamp, modifiedItem];
    })
   );
  const sortedItems = Object.entries(modifiedProductHist)
    .sort(([linkA, itemA], [linkB, itemB]) => {
      let dateA = itemA["link"] || linkA;
      let dateB = itemB["link"] || linkB;
      const dateComparison = dateA?.localeCompare(dateB);
      
      if (dateComparison === 0) {
        return (itemA["link"] || linkA).localeCompare(itemB["link"] || linkB);
      }
      return dateComparison;
    })
    .map(([timestamp, item]) => {
      let description, title, icon;

      const network = BASE_URL.includes('vertobackendappdev') ? 'testnet' : 'mainnet';
      
      // If no method exists, use item.event
      const method = item.method || item.event || "transport";
      const period = item.Period || item.period;
      const location = item.location;
      const physicalState = item.physicalState;
      
      switch (method) {
        case 'productcreation':
          title = <FormattedMessage id="explorer.timeline.productcreation" />;
          icon = faFileCirclePlus; // Or another appropriate icon from Font Awesome
          description = (
            <>
              <FormattedMessage id="explorer.timeline.process" /> <FormattedMessage id="explorer.timeline.productcreation" />
              <br />
              <FormattedMessage id="product.period1" /> {period}
              <br />
              <FormattedMessage id="product.location1" /> {location}
              <br />
              {item.id && (
                <Button
                  component="a"
                  href={`https://hashscan.io/${network}/account/${item.ownerAccountId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="xs"
                  variant="subtle"
                  color="blue"
                >
                  <FormattedMessage id="certificate.linktoledger.hedera" />
                </Button>
              )}
            </>
          );
          break;
        case 'transform':
        case 'transformLot':
          title = <FormattedMessage id="explorer.timeline.transformation" />;
          icon = faGears;
          description = (
            <>
              <FormattedMessage id="explorer.timeline.process" /> {physicalState}
              <br />
              <FormattedMessage id="product.period1" /> {period}
              <br />
              <FormattedMessage id="product.location1" /> {location}
              <br />
              <Button
                  component="a"
                  href={`https://hashscan.io/${network}/transaction/${item.id?.split('@')[1]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="xs"
                  variant="subtle"
                  color="blue"
                >
                  <FormattedMessage id="certificate.linktoledger.hedera" />
                </Button>
            </>
          );
          break;
        case 'transport':
        case 'transportLot':
          title = <FormattedMessage id="explorer.timeline.transport" />;
          icon = faDolly;
          description = (
            <>
              <FormattedMessage id="product.period1" /> {period}
              <br />
              <FormattedMessage id="product.location1" /> {location}
              <br />
              <FormattedMessage id="product.description1" /> {item.transportDescription}
              <br />
              <Button
                  component="a"
                  href={`https://hashscan.io/${network}/transaction/${item.id?.split('@')[1]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="xs"
                  variant="subtle"
                  color="blue"
                >
                  <FormattedMessage id="certificate.linktoledger.hedera" />
                </Button>
            </>
          );
          break;
        case 'transferOwner':
          title = <FormattedMessage id="explorer.timeline.sell" />;
          icon = faCashRegister;
          description = (
            <>
            <FormattedMessage id="explorer.timeline.prodforsale" />;
            <br />
              <Button
                  component="a"
                  href={`https://hashscan.io/${network}/transaction/${item.id?.split('@')[1]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="xs"
                  variant="subtle"
                  color="blue"
                >
                  <FormattedMessage id="certificate.linktoledger.hedera" />
                </Button>
            </>
          )
          break;
        default:
          title = <FormattedMessage id="explorer.timeline.operation" />;
          icon = faCircleInfo;
          description = (           
            <>
              <FormattedMessage id="explorer.timeline.process" /> {physicalState}
              <br />
              <FormattedMessage id="product.period1" /> {period}
              <br />
              <FormattedMessage id="product.location1" /> {location}
              <br />
              <Button
                  component="a"
                  href={`https://hashscan.io/${network}/transaction/${item.id?.split('@')[1]}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="xs"
                  variant="subtle"
                  color="blue"
                >
                  <FormattedMessage id="certificate.linktoledger.hedera" />
                </Button>
            </>
          );
      }

      return {
        timestamp,
        title,
        description,
        icon
      };
    });

  return (
    <div style={{ marginTop: "20px", maxWidth: "600px", margin: "auto" }}>
      <Timeline 
        active={0} 
        bulletSize={20} 
        lineWidth={2}
        styles={(theme) => ({
          item: {
            backgroundColor: 'transparent',
          },
          itemTitle: {
            color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.black,
          },
          itemBullet: {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[2],
          },
          itemLine: {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[3],
          },
        })}
      >
        {sortedItems.map((item, index) => (
          <Timeline.Item
            key={index}
            bullet={<FontAwesomeIcon icon={item.icon} />}
            title={item.title}
          >
            <Text color="dimmed" size="sm">
              {item.description}
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
}

const CertificatePage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [productDict, setProductDict] = useState({});
  const [productHist, setProductHist] = useState({});
  const dispatch = useDispatch();
  const fetchLedgerLot = useSelector((state) => state.products.fetchLedgerLot);
  const fetchLedgerLotHistory = useSelector((state) => state.products.fetchLedgerLotHistory);
  const intl = useIntl();
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState("");
  const [isSpecialAccount, setIsSpecialAccount] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      // Only fetch if we don't already have results
      if (!fetchLedgerLot?.result || !fetchLedgerLotHistory?.result) {
        setIsLoading(true);
        try {
          await dispatch(getFetchLedgerLot({ inputValue: id }));
          await dispatch(getFetchLedgerLotHistory({ inputValue: id }));
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchData();
  }, [dispatch, id]);   // Simplified dependencies
  
  useEffect(() => {
    // Process data only when results are available
    if (fetchLedgerLot?.result) {
      const product = fetchLedgerLot.result;
      
      // Check special account
      setIsSpecialAccount(product?.darkmode === 1);
  
      // Process ProductName
      if (product?.ProductName) {
        const [mainProductName, ...rest] = product.ProductName.split(",");
        const additionalFields = {};
  
        rest.join(",").split("\\r\\n").forEach((line) => {
          const [key, value] = line.split(":").map((str) => str.trim());
          if (key && value) {
            additionalFields[key] = value;
          }
        });
  
        // Update product dictionary
        setProductDict({
          ...fetchLedgerLot,
          result: {
            ...product,
            ProductName: mainProductName.trim(),
            ...additionalFields,
          },
        });
      }
  
      // Set product history
      setProductHist(fetchLedgerLotHistory);
  
      // Set QR code image URL
      if (product.ProductID) {
        const qrCodeUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/qrcode/${product.ProductID}.png`;
        setQrCodeImageUrl(qrCodeUrl);
      }
    }
  }, [fetchLedgerLot, fetchLedgerLotHistory]);
  
  // Separate effect for fetching the certificate
  useEffect(() => {
    const fetchProductCertificate = async () => {
      if (fetchLedgerLot?.result?.ProductID) {
        try {
          await fetch(`${BASE_URL}/getProductCertificate?product_id=${fetchLedgerLot.result.ProductID}&targetLanguage=en`);
        } catch (error) {
          console.error("Failed to fetch product certificate:", error);
        }
      }
    };
  
    fetchProductCertificate();
  }, [fetchLedgerLot?.result?.ProductID]);

  return (
    <MantineProvider theme={{ colorScheme: isSpecialAccount ? 'dark' : 'light' }}>
      <div
        style={{
          padding: '30px',
          background: isSpecialAccount
            ? 'linear-gradient(to bottom, #121212, #1A1B1E)' // Example gradient for dark mode
            : '#f9f9f9', // Light background color
          minHeight: '100vh', // Ensure the background covers the entire viewport
        }}
      >
      <Title
        order={3}
        weight={200}
        align="center"
        style={{
          color: isSpecialAccount ? '#FFA726' : '#333', // Custom color for dark theme
          marginBottom: '20px',
        }}
      >
        {intl.formatMessage({ id: 'explorer.title' })}
      </Title>
      <Center>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "20px" }}>
          {qrCodeImageUrl && (
            <img src={qrCodeImageUrl} alt="QR Code" style={{ maxWidth: '100%' }} />
          )}
          <Column>
            <Title
              order={3}
              weight={200}
              align="center"
              style={{
                marginTop: '20px',
                marginBottom: '30px',
                color: isSpecialAccount ? '#FFA726' : '#333', // Custom color for special account
                fontSize: isSpecialAccount ? '1.8rem' : '1.5rem', // Slightly larger text for special account
              }}
            >
              {<FormattedMessage id="explorer.section.pinfo" />}
            </Title>
            <ProductState product={productDict} style={{ marginTop: "20px"}}/>
          </Column>
          <Column>
            <Title
              order={3}
              weight={200}
              align="center"
              style={{
                marginTop: '20px',
                marginBottom: '30px',
                color: isSpecialAccount ? '#FFA726' : '#333', // Custom color for special account
                fontSize: isSpecialAccount ? '1.8rem' : '1.5rem', // Slightly larger text for special account
              }}
            >
              {<FormattedMessage id="explorer.section.phistory" />}
            </Title>
            {isLoading ? (
              <Loader />
            ) : (
            <Column>
              <ProductTimeline productHist={productHist} style={{ margin: "auto" }}/>
            </Column>
            )}
          </Column>
        </div>
      </Center>
    </div>
    </MantineProvider>
  );
};

// You may want to implement ProductTimeline similarly to how it was done in ProductCertificate

export default CertificatePage;