import * as React from "react";
import Card from "@mui/material/Card";
import { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./itemCard.css";
import AddIcon from "@mui/icons-material/Add";

import { FormattedMessage } from 'react-intl';

const getImages = async (productID, upc, product_name_id) => {
  const imageUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${productID}.png`;
  const defaultImage= `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/${product_name_id}.jpg`; 
  try {
    const response = await fetch(imageUrl);
    if (response.ok) {
      return imageUrl;
    } else {
      return defaultImage;
    }
  } catch (error) {
    return defaultImage;
  }  
}

const style = {
  image: {
    height: "200px",
    width: "350px",
    objectFit: "contain",
    // border: "3px solid red",
    left: "-30px",
    backgroundImage:
      "url('https://media.istockphoto.com/id/1272128530/photo/home-with-blue-siding-and-stone-fa%C3%A7ade-on-base-of-home.jpg?b=1&s=170667a&w=0&k=20&c=vdpWpslhj_oGFrLxbQ1N0nNmGj_gU-2qbfUSpdArziU=')",
  },
  button: {
    // border : "2px solid green",
    borderRadius: "20px",
    backgroundColor: "#ADD8E6",
    marginLeft: "72px",
    width: "150px",
    marginTop: "5px",
    height: "32px",
  },
  main: {
    // height: "450px",
    width: "350px",
    marginTop: "60px",
    marginLeft: "80px",
    marginRight: "80px",
  },
  name: {
    marginRight: "auto",
    display: "flex",
    // border : "2px solid blue",
    height: "40px",
    width: "100%",
  },
  description: {
    height: "40px",
    width: "100%",
    fontSize: "14px",
    // border: "2px solid black",
  },
  imageWrapper: {
    width: "100%",
  },
  img: {
    width: "100%",
  },
  content: {
    width: "350px",
  },
};

export default function RealEstateMediaCard({ item, selectedSale }) {

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      const imageUrl = await getImages(item?.productRefID, item?.upc, item?.product_name_id);
      setImageUrl(imageUrl);
    };

    fetchImages();
  }, [item?.productRefID, item?.upc, item?.product_name_id]);
  
  return (
    <Card style={style.main} className="card">
      <CardContent  style={style.content}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {imageUrl && <img style={{ ...style.img, width: 200, height: 200, marginBottom: "10px" }} src={imageUrl} alt={item.productName} />}
        </div>
        <div style={style.name}>
          <Typography style={{ marginTop: "3px" }} gutterBottom></Typography>
          <Typography style={{ marginLeft: "auto" }}>
            {item.location}
          </Typography>
        </div>
        <Typography
          gutterBottom
          variant="h5"
          style={{ marginTop: "0px", fontSize: "18px" }}
        >
          {item.productName.substring(0, 30)}
        </Typography>
        <Typography style={style.description}>
          {item.productName.substring(0, 30)}
        </Typography>
        <Button onClick={() => selectedSale(item)} style={style.button}>
          <AddIcon />
          &ensp;<FormattedMessage id="button.buy" />
        </Button>
      </CardContent>
    </Card>
  );
}